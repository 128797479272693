// @ts-nocheck
import * as $3Dmol from '3dmol/build/3Dmol';
import { HeatMapGrid } from 'react-grid-heatmap';
import { useEffect, useRef, useState } from 'react';
import { Switch } from '../../shared/ui/Switch';
import Papa from 'papaparse';
import { Button } from '../../shared/ui/Button';
import { Icon } from '../../shared/ui/Icon';
import { StructureDescription, SeqDescription } from './help_description';

const epitopes = ['A', 'B', 'C', 'D1', 'D2', 'E1', 'E21', 'E22', 'E3', 'F1', 'F2', 'F3'];

const epitopeColors = {
    A: '#005A60',
    B: '#2FBEAD',
    C: '#8E5F44',
    D1: '#D1A684',
    D2: '#FDE74C',
    E1: '#FE7D0E',
    E21: '#E94262',
    E22: '#4A4A4A',
    E3: '#909393',
    F1: '#2DAAF0',
    F2: '#7565FF',
    F3: '#D458FB',
    Z:  '#ffffff'
};

const BFactorColors = {
    0: '#005A60',
    1: '#2FBEAD',
    2: '#8E5F44',
    3: '#D1A684',
    4: '#FDE74C',
    5: '#FE7D0E',
    6: '#E94262',
    7: '#4A4A4A',
    8: '#909393',
    9: '#2DAAF0',
    10: '#7565FF',
    11: '#D458FB'
};


// Функция для загрузки файла
const downloadFile = ({ base64String, fileName }) => {
    const linkSource = `data:application/zip;base64,${base64String}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
};

// Функция для парсинга CSV данных
const parseCSV = (csvData) => {
    const parsedData = Papa.parse(csvData, {
        header: true,
        dynamicTyping: true,
    });

    return parsedData.data;
};

// Функция покраски атомов-эпитопов антитела
const colorAbByBFactor = (atom) => {
    return BFactorColors[Math.round(parseFloat(atom.b))]
};

const colorShade = (col, amt) => {
  col = col.replace(/^#/, '')
  if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

  let [r, g, b] = col.match(/.{2}/g);
  ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

  r = Math.max(Math.min(255, r), 0).toString(16)
  g = Math.max(Math.min(255, g), 0).toString(16)
  b = Math.max(Math.min(255, b), 0).toString(16)

  const rr = (r.length < 2 ? '0' : '') + r
  const gg = (g.length < 2 ? '0' : '') + g
  const bb = (b.length < 2 ? '0' : '') + b

  return `#${rr}${gg}${bb}`
}


// Компонент Result
const Result = ({ data, isSelectivity }) => {
    const [pdbData, setPdbData] = useState([]);
    const [rbdData, setRbdData] = useState([]);
    const [showAllEpitopes, setShowAllEpitopes] = useState(false);
    const [showACE, setShowACE] = useState(false);
    const [isShowText, setIsShowText] = useState(false);
    const [isShow3DText, setIsShow3DText] = useState(false);
    const containerFor3Dmol = useRef(null);
    const viewerRef = useRef(null);


    // useEffect для инициализации данных и создания 3D модели
    useEffect(() => {
        const decodePdb = atob(data.model_pdb);
        const pdb = decodePdb.split('\n').map((line) => {
            if (line.startsWith('ATOM')) {
                return line.split(' ').filter((item) => item !== '');
            }
        });

        const decodedCSVData = atob(data.rbd_epitopes_ace_base64);
        // console.log(parseCSV(decodedCSVData));
        const rbd = parseCSV(decodedCSVData).map((elRBD) => {
            let maxEpitope = data.predicted_epitope;
            let maxEpitopeValue = 0;

            if (elRBD[maxEpitope] > 0) {
                return {
                    ...elRBD,
                    maxEpitope: maxEpitope,
                };
            }

            epitopes.forEach((epitope) => {
                if (elRBD[epitope] > maxEpitopeValue) {
                    maxEpitopeValue = elRBD[epitope];
                    maxEpitope = epitope;
                }
                if (maxEpitopeValue == 0) {
                    maxEpitope = 'Z';
                }
            });
            // console.log(maxEpitope);

            return {
                ...elRBD,
                maxEpitope: maxEpitope,
            };
        });

        setPdbData(pdb);
        setRbdData(rbd);

        const viewer = $3Dmol.createViewer(containerFor3Dmol.current, {
            backgroundColor: 'rgb(22,28,39)',
        });

        viewerRef.current = viewer;
        viewer.addModel(decodePdb, 'pdb');
        viewer.render();
        viewer.zoomTo();
        viewer.zoom(1.2, 1000);
    }, [data]);

    // useEffect для обновления стиля 3D модели при изменении состояния
    useEffect(() => {
        const viewer = viewerRef.current;

        if (viewer) {
            viewer.setStyle(
                {},
                {
                    cartoon: {
                        colorfunc: (atom) => {
                            let color = colorShade(epitopeColors[data.predicted_epitope_3D], 50);
                            rbdData.forEach((elRBD) => {
                                if (elRBD.position_resi === atom.resi) {
                                    color = showAllEpitopes
                                        ? epitopeColors[elRBD.maxEpitope]
                                        : elRBD[data.predicted_epitope_3D] > 0
                                        ? epitopeColors[data.predicted_epitope_3D]
                                        : '#ffffff';

                                }
                            });
                            return color;
                        },
                    },
                }
            );

            if (showAllEpitopes) {
                viewer.setStyle({chain: 'Q', hetflag: true},{sphere: { radius: 2, colorfunc: colorAbByBFactor}});
            };

            if (showACE) {
                viewer.setStyle(
                    {
                        resi: rbdData
                            .filter((entry) => entry.ACE === 1)
                            .map((entry) => {
                                return entry.position_resi;
                            }),
                    },
                    { sphere: { radius: 1, color: epitopeColors[data.predicted_epitope] } }
                );
            }

            viewer.render();
        }
    }, [showAllEpitopes, showACE, rbdData, data.predicted_epitope]);

    const xLabelsEpitopes = data.epitope_names;
    const dataHeatmapEpitopes = [data.epitope_scores.map((score) => parseFloat(score.toFixed(1)))];

    const dataHeatmapEscape = [data.escape_data.map((score) => parseFloat(score.toFixed(1)))];
    var xLabelsEscape = [];
    data.strain_names.forEach((v) => {xLabelsEscape.push(<div className="F-Result__heatmap_labels">{v}</div>)});
    
    const dataHeatmapSelectivity = [data.strain_selectivity_3D.map((score) => parseFloat(score.toFixed(1)))];
    const xLabelsSelectivity = [];
    data.strain_names_selectivity.forEach((v) => {xLabelsSelectivity.push(<div className="F-Result__heatmap_labels">{v}</div>)});
    // const xLabelsEscape = data.strain_names
    const minEpiScore = Math.min(...data.epitope_scores).toFixed(1)
    const maxEpiScore = Math.max(...data.epitope_scores).toFixed(1)




    return (
        <div className="F-Result">
            <div className="container">
                <div className="F-Result__header">Input data</div>
                <div className="F-Result__protein-sequence">Heavy chain sequence: {data.input_heavy}</div>
                <div className="F-Result__protein-sequence">Light chain sequence: {data.input_light}</div>
                <div className="F-Result__protein-sequence">RBD sequence: {data.input_rbd}</div>
                <div className="F-Result__header">Sequence-based prediction</div>
                <div className='container'>
                        <div className='slider'>
                            <div onClick={() => setIsShowText((prevState) => !prevState)} className='slider__header'>
                                <div className='slider__title'>
                                    <Icon name={'question'} />
                                    Description
                                </div>
                                {isShowText ? <Icon name={'arrow-top'} /> : <Icon name={'arrow-down'} />}
                            </div>
                            {isShowText && (
                                <div className='slider__content'>
                                    {SeqDescription}
                                </div>
                            )}
                        </div>
                </div>
                <div className="F-Result__subheader">Predicted binding score</div>
                {/*<div className="F-Result__plain_text">Predicted binding probability for each epitope class: the higher the probability, the lower the transparency. The epitope class with the maximum probability score is highlighted under the heatmap.</div>*/}
                <div className="F-Result__legent">
                    <div className="F-Result__legent-line"></div>
                    <div className="F-Result__legent-flex">
                        <div className="F-Result__legent-start">Low ({minEpiScore})</div>
                        <div className="F-Result__legent-right">High ({maxEpiScore})</div>
                    </div>
                </div>
                <div className="F-Result__heatmap">
                    <HeatMapGrid
                        data={dataHeatmapEpitopes}
                        xLabels={xLabelsEpitopes}
                        cellRender={(x, y, value) => <div title={`Pos(${x}, ${y}) = ${value}`}>{value}</div>}
                        xLabelsStyle={(index) => ({
                            color: epitopeColors[xLabelsEpitopes[index]],
                            fontSize: '20px',
                        })}
                        yLabelsStyle={() => ({
                            fontSize: '.7rem',
                            textTransform: 'uppercase',
                            color: '#777',
                        })}
                        cellStyle={(x, y, ratio) => ({
                            // background: `rgb(20, 214, 188, ${ratio})`,
                            // background: `rgb(220, 220, 220, ${ratio})`,
                            background: epitopeColors[xLabelsEpitopes[y]],
                            //background: `${epitopeColors[xLabelsEpitopes[y]]}${Math.floor(ratio * 255).toString(16).padStart(2, 0)}`,
                            fontSize: '14px',
                            // color: `rgb(225, 225, 225, ${ratio})`,
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                        })}
                        cellHeight="54px"
                        xLabelsPos="bottom"
                        yLabelsPos="right"
                        square
                    />
                </div>
                <div className="F-Result__subheader">Predicted epitope class: {data.predicted_epitope_nn}</div>
                <div className="F-Result__header">Structure-based prediction</div>
                <div className='container'>
                        <div className='slider'>
                            <div onClick={() => setIsShow3DText((prevState) => !prevState)} className='slider__header'>
                                <div className='slider__title'>
                                    <Icon name={'question'} />
                                    Description
                                </div>
                                {isShow3DText ? <Icon name={'arrow-top'} /> : <Icon name={'arrow-down'} />}
                            </div>
                            {isShow3DText && (
                                <div className='slider__content'>
                                    {StructureDescription}
                                </div>
                            )}
                        </div>
                </div>
                { (data.PAE_score <= 14) ? (
                    <>  
                    <div className="F-Result__subheader">Predicted epitope class: {data.predicted_epitope_3D}</div>
                    <div className="F-Result__subheader">Predicted binding score: {data.PAE_score.toFixed(2)}</div>
                    <div className="F-Result__subheader">Predicted 3D structure of the antibody-RBD complex</div>
                    <div className="F-Result__toggle">
                        <Switch name="Show all epitope classes" checked={showAllEpitopes} handler={setShowAllEpitopes} />
                        <Switch name="Show ACE2-binding interface" checked={showACE} handler={setShowACE} />
                    </div>
                    <div className="F-Result__protein-sequence">
                        {rbdData.map((elRBD, index) => {
                            let color = showAllEpitopes
                                ? epitopeColors[elRBD.maxEpitope]
                                : elRBD[data.predicted_epitope] > 0
                                ? epitopeColors[data.predicted_epitope]
                                : '#ffffff';
                            return (
                                <span key={index} style={{ color }}>
                                    {elRBD.ACE && showACE ? (
                                        <span style={{ textDecoration: 'underline' }}>{elRBD.user_rbd}</span>
                                    ) : (
                                        elRBD.user_rbd
                                    )}
                                </span>
                            );
                        })}
                    </div>
                    <div className="F-Result__3dmol" ref={containerFor3Dmol}></div>
                    </>
                    ) : (
                    <>
                    <div className="F-Result__subheader">Predicted 3D structure of the antibody-RBD complex</div>
                    <div className="F-Result__plain_text">Сomplex was not constructed</div>
                    <div className="F-Result__3dmol" ref={containerFor3Dmol}></div>
                    </>)
                }
                <div className="F-Result__header">Escape mutations</div>
                <div className="F-Result__plain_text">Heatmap shows number of escape mutations between analyzed RBD sequence and RBD of SARS-COV2 strains.</div>
                <div className="F-Result__heatmap_escape">
                    <HeatMapGrid
                        data={dataHeatmapEscape}
                        xLabels={xLabelsEscape}
                        cellRender={(x, y, value) => <div title={`Pos(${x}, ${y}) = ${value}`}>{value}</div>}
                        xLabelsStyle={(index) => ({
                            color: `white`,
                            fontSize: '20px',
                        })}
                        yLabelsStyle={() => ({
                            fontSize: '.7rem',
                            textTransform: 'uppercase',
                            color: '#777',
                        })}
                        cellStyle={(_x, _y, ratio) => ({
                            background: `rgb(20, 214, 188, ${ratio})`,
                            fontSize: '14px',
                            color: `white`,
                            border: 'none',
                            borderRadius: '5px',
                        })}
                        cellHeight="27px"
                        xLabelsPos="bottom"
                        yLabelsPos="right"
                        square
                    />
                </div>

                {(data.strain_selectivity_3D.length != 0) ? (
                    <>
                    <div className="F-Result__header">Strain Selectivity</div>
                    <div className="F-Result__plain_text">Selectivity values below zero mean that the analyzed antibody do not bind to the RBD of the corresponding strain. The higher the selectivity value, the higher the binding score.</div>
                    <div className="F-Result__heatmap_escape">
                        <HeatMapGrid
                            data={dataHeatmapSelectivity}
                            xLabels={xLabelsSelectivity}
                            cellRender={(x, y, value) => <div title={`Pos(${x}, ${y}) = ${value}`}>{value}</div>}
                            xLabelsStyle={(index) => ({
                                color: `white`,
                                fontSize: '20px',
                            })}
                            yLabelsStyle={() => ({
                                fontSize: '.7rem',
                                textTransform: 'uppercase',
                                color: '#777',
                            })}
                            cellStyle={(_x, _y, ratio) => ({
                                background: `rgb(20, 214, 188, ${ratio})`,
                                fontSize: '14px',
                                color: `white`,
                                border: 'none',
                                borderRadius: '5px',
                            })}
                            cellHeight="27px"
                            xLabelsPos="bottom"
                            yLabelsPos="right"
                            square
                        />
                    </div>
                    </>
                    ):(null)}

                <div className="F-Result__wrap-download">
                    <Button handler={() => downloadFile({ base64String: data.archive })}>Download</Button>
                </div>
            </div>
        </div>
    );
};

export { Result };
