import './index.css';

type Icon = {
    name: 'logout' | 'arrow-top' | 'arrow-left' | 'upload' | 'out-link' | 'arrow-down' | 'question';
};

const Icon = ({ name }: Icon) => {
    let icon = null;

    switch (name) {
        case 'logout':
            icon = (
                <svg className="UI-Icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <g clipPath="url(#a)">
                        <path d="M21.536 17.893h-1.883a.422.422 0 0 0-.33.155 8.675 8.675 0 0 1-.6.657 9.479 9.479 0 0 1-6.712 2.777 9.478 9.478 0 0 1-6.712-2.778 9.463 9.463 0 0 1-2.037-3.013A9.39 9.39 0 0 1 2.519 12a9.437 9.437 0 0 1 2.78-6.704 9.478 9.478 0 0 1 6.713-2.778 9.478 9.478 0 0 1 7.313 3.434c.08.099.203.155.329.155h1.883a.213.213 0 0 0 .18-.33A11.523 11.523 0 0 0 11.98.483 11.505 11.505 0 0 0 .482 12.118c.064 6.308 5.202 11.4 11.529 11.4 4.074 0 7.652-2.11 9.704-5.296a.214.214 0 0 0-.18-.33Zm2.381-6.062-3.8-3a.215.215 0 0 0-.35.169v2.036h-8.41a.215.215 0 0 0-.214.214v1.5c0 .118.096.214.214.214h8.41V15c0 .18.21.281.35.169l3.8-3a.215.215 0 0 0 0-.338Z" />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path d="M0 0h24v24H0z" />
                        </clipPath>
                    </defs>
                </svg>
            );
            break;
        case 'arrow-top':
            icon = (
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_504_1010)">
                        <path
                            d="M2.18888 16.1429L3.96522 16.1429C4.08602 16.1429 4.1997 16.0926 4.27076 16.0103L10.9996 8.1433L17.7283 16.0103C17.7994 16.0926 17.9131 16.1429 18.0339 16.1429L19.8102 16.1429C19.9642 16.1429 20.0542 15.9942 19.9642 15.8877L11.613 6.12232C11.3098 5.76875 10.6893 5.76875 10.3885 6.12232L2.0373 15.8877C1.94493 15.9942 2.03493 16.1429 2.18888 16.1429V16.1429Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_504_1010">
                            <rect width="18" height="18" fill="white" transform="translate(2 2)" />
                        </clipPath>
                    </defs>
                </svg>
            );
            break;
        case 'arrow-left':
            icon = (
                <svg className="UI-Icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <path d="M21.642 10.982H5.97l9.38-8.143a.213.213 0 0 0-.139-.375h-2.37a.43.43 0 0 0-.282.104L2.437 11.351a.855.855 0 0 0 0 1.294l10.181 8.837a.21.21 0 0 0 .14.053h2.45c.199 0 .29-.246.14-.375L5.97 13.017h15.672a.215.215 0 0 0 .215-.214v-1.607a.215.215 0 0 0-.215-.214Z" />
                </svg>
            );
            break;
        case 'upload':
            icon = (
                <svg className="UI-Icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                    <path d="M14.213 12.786H1.787a.144.144 0 0 0-.145.143V14c0 .079.064.143.145.143h12.425c.08 0 .144-.064.144-.143v-1.071a.144.144 0 0 0-.144-.143Zm-6.325-1.982a.144.144 0 0 0 .175.04.144.144 0 0 0 .05-.04l2-2.53A.142.142 0 0 0 10 8.042H8.677V2a.143.143 0 0 0-.143-.143H7.462A.143.143 0 0 0 7.32 2v6.041H6a.142.142 0 0 0-.112.23l2 2.533Z" />
                </svg>
            );
            break;
        case 'out-link':
            icon = (
                <svg className="UI-Icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                    <path d="m12.67 12.667-.003.001.003-.001Zm-9.337.004c0-.003-.001-.004-.003-.004h.003v.004Zm0-9.342v.004-.004Zm9.334 9.338v-4H14v4.001A1.331 1.331 0 0 1 12.67 14H3.33A1.33 1.33 0 0 1 2 12.67V3.33C2 2.594 2.597 2 3.331 2h4.002v1.333h-4v9.334h9.334ZM3.333 3.329v.004-.004Zm0 9.342c0-.003-.001-.004-.003-.004h.003v.004Zm4-9.338h-4v9.334h9.334v-4H14v4.001A1.331 1.331 0 0 1 12.67 14H3.33A1.33 1.33 0 0 1 2 12.67V3.33C2 2.594 2.597 2 3.331 2h4.002v1.333Zm5.334 0v2a.667.667 0 1 0 1.333 0V2.667A.666.666 0 0 0 13.333 2h-2.666a.667.667 0 1 0 0 1.333h2Z" />
                    <path d="m8.471 8.471 5.334-5.333a.669.669 0 0 0 .008-.95.667.667 0 0 0-.951.007L7.529 7.53a.667.667 0 0 0 .942.942Z" />
                </svg>
            );
            break;
        case 'arrow-down':
            icon = (
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19.8111 5.85714H18.0348C17.914 5.85714 17.8003 5.90737 17.7292 5.98973L11.0004 13.8567L4.27165 5.98973C4.2006 5.90737 4.08691 5.85714 3.96612 5.85714H2.18978C2.03583 5.85714 1.94583 6.0058 2.03583 6.11228L10.387 15.8777C10.6902 16.2312 11.3107 16.2312 11.6115 15.8777L19.9627 6.11228C20.0551 6.0058 19.9651 5.85714 19.8111 5.85714V5.85714Z"
                        fill="white"
                    />
                </svg>
            );
            break;
        case 'question':
            icon = (
                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_504_1007)">
                        <path
                            d="M13.5 2C7.14933 2 2 7.14933 2 13.5C2 19.8507 7.14933 25 13.5 25C19.8507 25 25 19.8507 25 13.5C25 7.14933 19.8507 2 13.5 2ZM13.5 23.0491C8.22746 23.0491 3.95089 18.7725 3.95089 13.5C3.95089 8.22746 8.22746 3.95089 13.5 3.95089C18.7725 3.95089 23.0491 8.22746 23.0491 13.5C23.0491 18.7725 18.7725 23.0491 13.5 23.0491Z"
                            fill="#14D6BC"
                        />
                        <path
                            d="M12.2675 8.98214C12.2675 9.30893 12.3973 9.62233 12.6283 9.8534C12.8594 10.0845 13.1728 10.2143 13.4996 10.2143C13.8264 10.2143 14.1398 10.0845 14.3709 9.8534C14.6019 9.62233 14.7317 9.30893 14.7317 8.98214C14.7317 8.65536 14.6019 8.34196 14.3709 8.11089C14.1398 7.87981 13.8264 7.75 13.4996 7.75C13.1728 7.75 12.8594 7.87981 12.6283 8.11089C12.3973 8.34196 12.2675 8.65536 12.2675 8.98214ZM14.1157 11.8571H12.8835C12.7706 11.8571 12.6782 11.9496 12.6782 12.0625V19.0446C12.6782 19.1576 12.7706 19.25 12.8835 19.25H14.1157C14.2286 19.25 14.321 19.1576 14.321 19.0446V12.0625C14.321 11.9496 14.2286 11.8571 14.1157 11.8571Z"
                            fill="#14D6BC"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_504_1007">
                            <rect width="23" height="23" fill="white" transform="translate(2 2)" />
                        </clipPath>
                    </defs>
                </svg>
            );

    }

    return icon;
};

export { Icon };
