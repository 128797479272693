import './index.css'

const Tooltip = ({text}: {text?: string}) => {
    return <span className="UI-Tooltip">
    <i>?</i>
    <span>{text}</span>
</span>
}

export {Tooltip};
