export const StructureDescription = (
    <p>
    Strucutre-based approach was applied to predict epitope class and antibody-RBD complex. 
    Complex are charachterized by binding score, an average PAE score. Low PAE score (near to zero)
    is determining high confidence of antibody-RBD complex. If PAE &gt; 14, we consider that RBD and antibody have not bound
    and only RBD strucutre will be shown.

    Antibody structure are colored as shadow of predicted epitope class color.
    RBD residiues of the predicted epitope class are color coded. By default only predicted epitope class is shown.  
    Colors of epitope classes corresspond to colors of sequenced-based prediction results visualisation. 
    To look on all epitopes class use the toggle "Show all epitope classes", than known antibodies will shown 
    as a sphere and colored according to binded epitope class.
    Please, use toggle "Show ACE domain" to highlight ACE domain. 

    </p>
);

export const SeqDescription = (
    <p>
    Predicted binding score are shown for each analyzed epitope class. Minimum and maximum values
    are shown on legend scale. 
    The epitope class with the maximum binding score is highlighted under the heatmap.
    </p>
);