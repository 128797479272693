import React, { ForwardedRef } from 'react';
import './index.css';
import { Icon } from '../Icon';
import { Tooltip } from '../Tooltip';

type Option = {label: string, value: string};


type SelectProps = {
    name: string;
    label: string;
    helpText: string;
    options: Option[];
};

const Select = React.forwardRef(
    ({ name, label, helpText, options }: SelectProps, ref: ForwardedRef<HTMLSelectElement | null>) => (
        <div className="UI-Select">
            <label className="UI-Select__label">
                <span className="UI-Select__field-name">{label}{helpText && <Tooltip text={helpText}/>}</span>
                <span className="UI-Select__wrap-select">
                    <select className="UI-Select__select" ref={ref} name={name}>
                        {options.map((option) => (
                            <option key={option.label} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
{/*                    <span className="UI-Select__arrow">
                        <Icon name="arrow-down" />
                    </span>*/}
                </span>
            </label>
        </div>
    )
);

export { Select };
