import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Alert } from '../../shared/ui/Alert';
import { Textarea } from '../../shared/ui/Textarea';
import { Spin } from '../../shared/ui/Spin';
import { Button } from '../../shared/ui/Button';
import { Checkbox } from '../../shared/ui/Checkbox';
import { Result } from './Result';
import { Select } from '../../shared/ui/Select';
import { Tooltip } from '../../shared/ui/Tooltip';
import { strains } from './data';
import './index.css';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { TextInput } from '../../shared/ui/TextInput';

const baseURL = 'https://rbdaim.2a2i.org/';
const host = baseURL + 'inference';

const Home = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const URLParams = new URLSearchParams(location.search);
    const id = URLParams.get('id');
    const isResultByLink = !!id;
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [dataResult, setDataResult] = useState<null | object>(null);
    const [error, setError] = useState<null | string>(null);
    const heavyTextareaEl = useRef<HTMLTextAreaElement>(null);
    const lightTextareaEl = useRef<HTMLTextAreaElement>(null);
    const rbdTextareaEl = useRef<HTMLTextAreaElement>(null);
    const strainSelectEl = useRef<HTMLSelectElement>(null);
    const emailInputEl = useRef<HTMLInputElement>(null);
    const [idRunningService, setIdRunningService] = useState()

    const heavyExampleText =
        'EVQLVESGGGLVQPGGSLRLSCAASGFTVSSNYMSWVRQAPGKGLEWVSVIYSGGSTFYADSVKGRFTISRDNSMNTLFLQMNSLRAEDTAVYYCARVLPMYGDYLDYWGQGTLVTVSS';
    const lightExampleText =
        'DIVMTQSPSSLSASVGDRVTITCRASQSISRYLNWYQQKPGKAPKLLIYAASSLQSGVPSRFSGSGSGTDFTLTISSLQPEDFATYYCQQSYSTPPEYTFGQGTKLEIK';
    const rbdExampleText =
        'NLCPFGEVFNATRFASVYAWNRKRISNCVADYSVLYNSASFSTFKCYGVSPTKLNDLCFTNVYADSFVIRGDEVRQIAPGQTGKIADYNYKLPDDFTGCVIAWNSNNLDSKVGGNYNYLYRLFRKSNLKPFERDISTEIYQAGSTPCNGVEGFNCYFPLQSYGFQPTNGVGYQPYRVVVLSFELLHAPATVCGP';

    const handlePasteExample = (): void => {
        if (heavyTextareaEl && heavyTextareaEl.current) {
            heavyTextareaEl.current.value = heavyExampleText;
        }
        if (lightTextareaEl && lightTextareaEl.current) {
            lightTextareaEl.current.value = lightExampleText;
        }
        // if (rbdTextareaEl && rbdTextareaEl.current) {
        //     rbdTextareaEl.current.value = rbdExampleText;
        // }
        // if (strainSelectEl && strainSelectEl.current) {
        //     strainSelectEl.current.value = "Wuhan";
        // }
    };

    const handleSubmitForm = async (event: SyntheticEvent) => {
        event.preventDefault();
        let heavyValue = '';
        let lightValue = '';
        let rbdValue = '';
        let emailValue = '';

        if (heavyTextareaEl && heavyTextareaEl.current) {
            heavyValue = heavyTextareaEl.current.value;
            if (heavyValue === '') {
                setError('Heavy chain is required');
                return
            }
        }

        if (lightTextareaEl && lightTextareaEl.current) {
            lightValue = lightTextareaEl.current.value;
            if (lightValue === '') {
                setError('Light chain is required');
                return
            }
        }

        if (rbdTextareaEl && rbdTextareaEl.current) {
            rbdValue = rbdTextareaEl.current.value;
            // console.log('text',rbdValue)
        }

        if (strainSelectEl && strainSelectEl.current && rbdValue == '') {
            rbdValue = strainSelectEl.current.value;
            // console.log('select', rbdValue)
        }

        if (emailInputEl && emailInputEl.current) {
            emailValue = emailInputEl.current.value;

            if (emailValue !== '' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue)) {
                setError('Invalid email address');
                return;
            }
        }

        setIsLoading(true);

        const data = {
            seq_light: lightValue,
            seq_heavy: heavyValue,
            seq_rbd: rbdValue,
            selectivity: isChecked,
            email: emailValue || null,
        };

            try {
                const response = await fetch(host, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });

                const responseData = await response.json();
                const { inference_uuid } = responseData;
                setIdRunningService(inference_uuid);

                const eventSource = new EventSource(`${host}/${inference_uuid}/status`);

                eventSource.addEventListener('result', (event: MessageEvent) => {
                    try {
                        const jsonString = event.data.replace(/'/g, '"');
                        const data = JSON.parse(jsonString);
                        setIsLoading(false);
                        setIsSuccess(true);
                        setDataResult(data);
                    } catch (e) {
                        console.log(e, event.data);
                    } finally {
                        eventSource.close();
                    }
                });

                eventSource.addEventListener('error', (event: MessageEvent) => {
                    console.error('Error:', event.data);
                    setError('EventSource failed');
                    eventSource.close();
                });

                eventSource.onerror = (error) => {
                    console.error('EventSource failed', error);
                    setError('EventSource failed');
                    eventSource.close();
                };

                eventSource.addEventListener('timeout', (event: MessageEvent) => {
                    console.error('Timeout:', event.data);
                    setIsLoading(false);
                    setIsSuccess(false);
                    eventSource.close();
                });
            }  catch (error) {
                console.error('Request error:', error);
                setIsLoading(false);
                setIsSuccess(false);
            }
	
    };

    const headerTextArea = (name: string, helpText: string) => (
        <div className="F-Upload__header-text-area">
            <span className="UI-Textarea__field-name">
                {name}
                <Tooltip text={helpText} />
            </span>
            <span className="F-Upload__past-example" onClick={handlePasteExample}>
                paste example
            </span>
        </div>
    );

    const handleCheck = (): void => {
        setIsChecked(!isChecked);
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
			try {
				const response = await fetch(`${host}/${id}`);
				if (!response.ok) {
					navigate('/', { replace: true });
					window.location.reload();
				}
				const { data } = await response.json();
				setIsSuccess(true);
				setDataResult(data);
			} catch (error) {
				console.error('Fetch error:', error);
				setIsSuccess(false);
			} finally {
				setIsLoading(false);
			}
		};

        if (isResultByLink) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (error) {
           window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [error]);

    return (
        <>
            <div className="App__title">
                <h1>RBD-AIM</h1>
                <p>RBD Antibody Interaction Mapper</p>
            </div>

            {isSuccess ? (
                <Result data={dataResult} isSelectivity={isChecked} />
            ) : (
                <>
                    {isLoading ? (
                        <div className="F-Upload">
                            <div className="F-Upload__wrap-spin">
                                <Spin className="F-Upload__spin" />
                            </div>
                            <p style={{ textAlign: 'center' }}>
                                Your request is&nbsp;being processed. This may take a&nbsp;few minutes.
                                <br />
                                Please do&nbsp;not refresh the page.
                            </p>
                           </div>
                    ) : (
                        <form className="F-Upload" onSubmit={handleSubmitForm}>
                            {error && (
                                <div className="F-Upload__container-alert">
                                    <Alert type="Error">{error}</Alert>
                                </div>
                            )}
                            <Textarea
                                ref={heavyTextareaEl}
                                name="heavy"
                                label={headerTextArea(
                                    'Heavy chain',
                                    'Amino acid sequence of heavy chain variable region (VH)'
                                )}
                                rows={6}
                            />
                            <Textarea
                                ref={lightTextareaEl}
                                name="light"
                                label="Light chain"
                                rows={6}
                                helpText="Amino acid sequence of light chain variable region (VL)"
                            />
                            <div className="F-Upload__hr"></div>
                            <Select
                                ref={strainSelectEl}
                                name="rbd_strain"
                                label="Select SARS-COV2 strains"
                                helpText="RBD sequence of this strain will be used in analysis"
                                options={Object.values(strains)}
                            />
                            <div className="F-Upload__or">or</div>
                            <Textarea
                                ref={rbdTextareaEl}
                                name="rbd"
                                label="Paste RBD sequence"
                                rows={6}
                                helpText="Amino acid sequence of RBD"
                            />
                            <Checkbox
                                name="Perform selectivity analysis"
                                handler={handleCheck}
                                helpText="Selectivity analysis shows how selected antibody will binding with other SRAS-COV2 strains. Note that this will significantly extend the computation time."
                            />
                            <div className="F-Upload__hr"></div>
                            <p className="F-Upload__email-text">
                                Processing may take a&nbsp;long time, if&nbsp;you specify your email address,
                                we&nbsp;will send you a&nbsp;notification at&nbsp;the end of&nbsp;it.
                            </p>
                            <TextInput ref={emailInputEl} name="email" label="Email" type="email" />
                            <div className="F-Upload__footer">
                                <Button>Predict</Button>
                            </div>
                        </form>
                    )}
                </>
            )}
        </>
    );
};

export { Home };
