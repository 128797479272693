import classNames from 'classnames';
import './index.css';
import React, { ForwardedRef } from 'react';
import { Tooltip } from '../Tooltip';

type CheckboxProps = {
    name: string;
    handler?: () => void;
    helpText?: string;
};

const Checkbox = React.forwardRef(({ name, handler, helpText }: CheckboxProps, ref: ForwardedRef<HTMLInputElement | null>) => (
    <label className={classNames('UI-Checkbox')} >
        <input ref={ref} className="UI-Checkbox__input" type="checkbox" name={name} onClick={handler}/>
        <span className="UI-Checkbox__fake-box"></span>
        <span className="UI-Checkbox__wrap-label">
            <span className="UI-Checkbox__label-text">{name}</span>{helpText && <Tooltip text={helpText}/>}
        </span>
    </label>
));

export { Checkbox };
