import classNames from 'classnames';
import './index.css';
import React, { ForwardedRef } from 'react';

type SwitchProps = {
    name: string;
    checked: boolean;
    handler: (checked: boolean) => void;
};

const Switch = React.forwardRef(
    ({ name, checked, handler }: SwitchProps, ref: ForwardedRef<HTMLInputElement | null>) => (
        <label className={classNames('UI-Switch')}>
            <input
                ref={ref}
                className="UI-Switch__input"
                type="checkbox"
                name={name}
                checked={checked}
                onChange={() => handler(!checked)}
            />
            <span className={classNames('UI-Switch__slider', { 'UI-Switch__slider--checked': checked })}></span>
            <span className="UI-Switch__name">{name}</span>
        </label>
    )
);

export { Switch };
